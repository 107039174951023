import React from 'react'
import { Tabs, Tab } from '@material-ui/core'

import ContractorProfile from './ContractorProfile'
import BidTitle from './bidTitle'
import ButtonRow from './ButtonRow'
import { Bid, Job } from '../../../apollo/types'
import BidChat from '../../BidChat'

interface Props {
	bid?: Bid
	job: Job
	onClose?: () => void
}

const JobBidModal = ({ bid, job, onClose }: Props) => {
	const [tab, setTab] = React.useState(0)
	function handleTabChange(event: React.ChangeEvent<{}>, newValue: number) {
		setTab(newValue);
	}

	if (!bid || !job) { return null }

	return (
		<>
			<BidTitle bid={bid} jobCompletedOn={job.completedOn} />
			<ButtonRow job={job} bid={bid} reload={onClose}>
				<Tabs
					value={tab}
					onChange={handleTabChange}
					style={{ marginBottom: 12 }}
				>
					<Tab label="Company Chat" />
					<Tab label="Company Profile" />
				</Tabs>
			</ ButtonRow>
			{tab === 0 && <BidChat bidId={bid.id} bidChatId={bid.bidChat.id} disabled={bid.status === 'REJECTED'} />}
			{tab === 1 && <ContractorProfile contractor={bid.contractor} />}
		</>
	)
}


export default React.memo(JobBidModal)
