import React from 'react'
import styled from 'styled-components'
import { Typography, Button } from '@material-ui/core'
import { NewReleases } from '@material-ui/icons'
import IconCard from '../ui/IconCard'
import { Link } from 'gatsby'

const NoJobsYet = ({single}: {single?: boolean}) => (
	<Outer>
		<IconCard icon={<NewReleases style={{ width: 84, height: 84, color: '#666' }} />}>
			<div>
				<Typography variant="h6">No Jobs</Typography>
				<Typography variant="subtitle1">You currently don’t have any jobs{single && ' for this property'}</Typography>
				<NewJobLink to="/jobs/new">
					<Button >
						Create A Job
					</Button>
				</NewJobLink>
			</div>
		</IconCard>
	</Outer>
)

export default NoJobsYet

const NewJobLink = styled(Link)`
	display: flex;
	align-items: stretch;
	flex-direction: column;
	text-decoration: none;
	margin-top: 12px;
`

const Outer = styled.div`
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: center;
	height: 100%;
`
