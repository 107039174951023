import * as React from 'react'
import styled from 'styled-components'
import { Typography, Button } from '@material-ui/core'
import SuggestToContractor from './suggestToContractor'
import EditableText from '../ui/EditableText'
import { useEditJobHomeownerMutation } from '../../apollo/generated'
import Swal from 'sweetalert2'

interface Props {
	title: string
	description: string
	createdAt: Date
	date: Date
	viewDetailsPressed?: () => void
	refetch?: () => void
	id: string
	thumbnailSlug?: string
}

const d_string = (d: Date) =>
	`${`${d.getMonth() +
		1}`}/${`${d.getDate()}`}/${`${d.getFullYear()}`.substring(2)}`

const JobTitleArea = ({
	thumbnailSlug,
	title,
	description,
	viewDetailsPressed,
	date,
	id,
	refetch
}: Props) => {
	const [EditJobHomeowner] = useEditJobHomeownerMutation()

	const editJob = (field: 'date' | 'description') => async (r: string) => {
		try {
			await EditJobHomeowner({ variables: { jobId: id, [field]: r } })
			refetch && refetch()
		} catch (e) {
			Swal.fire('Could not update job', e.message, 'error')
		}
	}

	return (
		<Container>
			<JobImg
				src={require(`../../images/jobThumbnails/${
					thumbnailSlug ? thumbnailSlug : 'default'
				}.jpeg`)}
			/>
			<JobTitleCopy>
				<JobTitle>{title}</JobTitle>
				<EditableText
					onChange={editJob('description')}
					multiline
					title="Job Description"
					value={description}
				>
					<JobDescr>{description || <i>No Description Provided</i>}</JobDescr>
				</EditableText>
				<EditableText
					onChange={editJob('date')}
					type="date"
					title="Ideal Start Date"
					value={date.toString()}
				>
					<JobAddtlInfo>Ideal Start Date: {d_string(date)}</JobAddtlInfo>
				</EditableText>
			</JobTitleCopy>
			<DetailsAndInvitation>
				<DetailsButton onClick={viewDetailsPressed}>View Details</DetailsButton>
				<SuggestToContractor jobId={id} />
			</DetailsAndInvitation>
		</Container>
	)
}

const Container = styled.div`
	display: flex;
	@media (max-width: 850px) {
    flex-direction: row;
    flex-wrap: wrap;
    text-align: left;
		margin-bottom: 0;

		> * {
			margin: 10px;
		}
	}
	align-items: center;
	padding-top: .5rem;
	margin-bottom: 16px;
`

const JobImg = styled.img`
	background: #d8d8d8;
	border: 1px solid #979797;
	border-radius: 2px;
	width: 130px;
	height: 106px;
	margin-right: 16px;
	margin-bottom: 0;
	object-fit: cover;

	@media (max-width: 850px) {
		width: 84px;
    height: 84px;
	}
`

const JobTitleCopy = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;

	@media (max-width: 850px) {
		max-width: Calc(100% - 130px);
		> * {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			max-width: 100%;
		}
	}
`

const JobTitle = styled(Typography).attrs({ variant: 'body1' })`
	&& {
		font-size: 20px;
		color: #fff;
		letter-spacing: 0.12px;
		font-weight: bold;
		text-transform: capitalize;
	}
`

const JobDescr = styled(JobTitle).attrs({ variant: 'body2' })`
	&& {
		font-weight: 300;
		opacity: 0.87;
		font-size: 14px;
		letter-spacing: 0.08px;
		margin-bottom: 6px;
		margin-top: 2px;
		max-height: 320px;
		text-overflow: ellipsis;
		overflow: hidden;
	}
`

const JobAddtlInfo = styled(JobTitle)`
	&& {
		font-size: 14px;
		opacity: 0.75;
	}
`

const DetailsButton = styled(Button).attrs({
	size: 'small',
})`
	margin-bottom: 20px;
`

const DetailsAndInvitation = styled.div`
	display: flex;
	margin-left: auto;
	flex-direction: column;
	margin-left: 20px;

	@media (max-width: 850px) {
		margin-left: 10px;

		form {
			margin-bottom: 8px;
		}
	}
`

export default JobTitleArea
