import React from 'react'
import styled from 'styled-components'
import { Button, Typography } from '@material-ui/core'
import { useMutation } from '@apollo/react-hooks'
import ResponsiveModal from '../../ui/ResponsiveModal'
import Swal from 'sweetalert2';

import { HIRE_BID, REJECT_BID, COMPLETE_JOB_HOMEOWNER, REOPEN_JOB_HOMEOWNER } from '../../../apollo/mutations'
import { Bid, Job } from '../../../apollo/types'
import { MutationFn, MutationOptions } from 'react-apollo';
import WaitButton from '../../ui/WaitButton';

interface Props {
  job: Job
  bid: Bid
  children: React.ReactNode
  reload: () => void
}

const wrapSwal = (text: string) => async () => {
  Swal.fire({
    title: 'Success!',
    text,
    type: 'success'
  })
  return Promise.resolve()
}

const ButtonRow = ({ job, bid, reload, children }: Props) => {
  const [confirmationOpen, setConfirmationOpen] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false)
  const jobId = job && job.id;
  const bidId = bid && bid.id;
  const [completeJob] = useMutation(COMPLETE_JOB_HOMEOWNER, {
    variables: { jobId },
  });
  const [rejectBid] = useMutation(REJECT_BID, {
    variables: { jobId, bidId },
  });
  const [hireBid] = useMutation(HIRE_BID, {
    variables: { jobId, bidId },
  });
  const [reopenJob] = useMutation(REOPEN_JOB_HOMEOWNER, {
    variables: { jobId },
  });

  function doTask <T, TV>(mut: MutationFn<T, TV>) {
    return async (v?: MutationOptions<T, TV>) => {
      setLoading(true)
      try {
        const r = await mut(v)
        setLoading(false)
        return r
      } catch (e) {
        Swal.fire('An Error Occurred', e.message, 'error')
      }
      setLoading(false)
    }
  }

  let actionButton
  if (bid.status === 'REJECTED') {
    actionButton = <Typography>Bid Rejected</Typography>
  } else if (bid.status === "HIRED" && !job.completedOn) {
    actionButton = (
      <WaitButton inProgress={isLoading} onClick={() => doTask(completeJob)().then(wrapSwal('You may re-open the job from your job archive any time. If you re-open a completed job, your Fairmarket Contractor will see the job as "Open". A great tool for warranty servicing!')).then(reload)} buttonProps={{size: 'small' }}>
        Mark Completed
			</WaitButton>
    )
  } else if (job.completedOn) {
    actionButton = (
      <WaitButton inProgress={isLoading} onClick={() => doTask(reopenJob)().then(wrapSwal('Your Fairmarket Contractor now see\'s this job as "Open".')).then(reload)} buttonProps={{size: 'small' }}>
        Re-Open Job
      </WaitButton>
    )
  } else if (bid.status === "SUBMITTED") {
    actionButton = (
      <>
        <WaitButton inProgress={isLoading} onClick={() => doTask(rejectBid)().then(wrapSwal('Bid Has Been Rejected')).then(reload)} buttonProps={{size: 'small', variant: 'text' }}>
          Reject
        </WaitButton>
        <WaitButton inProgress={isLoading} onClick={() => setConfirmationOpen(true)} buttonProps={{size: 'small'}}>
          Hire
        </WaitButton>
        <ResponsiveModal style={{ height: 140, width: 400, display: 'flex' }} open={confirmationOpen} onClose={() => setConfirmationOpen(false)}>
          <Typography align="center">
            Are You Sure You Want to Hire This Contractor?
          </Typography>
          <ButtonContainer>
            <CancelButton buttonProps={{size: 'small', variant: 'text' }} onClick={() => setConfirmationOpen(false)}>Cancel</CancelButton>
            <YesButton size="small" onClick={() => doTask(hireBid)().then(wrapSwal('Either you or your Fairmarket Contractor can mark the job as "Complete" at the appropriate time.')).then(reload)}>Yes</YesButton>
          </ButtonContainer>
        </ResponsiveModal>
      </>
    )
  }

  return (
    <Outer>
      {children}
      <Spacer />
      {actionButton}
    </Outer>
  )
}

export default ButtonRow

const Spacer = styled.div`	
  margin-left: auto;	
`

const Outer = styled.div`
	margin-top: 24px;
	margin-bottom: 24px;
	display: flex;
	align-items: center;
  justify-content: flex-end;

  * {
    color: white;
    border-color: white;
  }

	@media (min-width: 850px) {
		> *:last-child {
			margin-left: 8px;
		}
	}

	@media (max-width: 849px) { 
    ${Spacer} {	
      display: none;	
    }
		flex-direction: column;
		align-items: stretch;
		margin-top: 12px;
		margin-bottom: 12px;
		> * {
			margin: 4px;
		}
	}
`

const ButtonContainer = styled.div`
  display: flex;
  margin-top: auto;
  justify-content: center;
  align-items: center;
`

const CancelButton = styled(Button)`
  min-width: 120px;
`

const YesButton = styled(Button)`
  margin-left: 15px;
  min-width: 120px;
`