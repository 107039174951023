import React from 'react'
import styled from 'styled-components'
import { Typography } from '@material-ui/core'
import { IBid } from '../../../pages/jobs'
import FallbackAvatar from '../../ui/FallbackAvatar'

const BidTitle = ({
	bid,
	jobCompletedOn
}: {
	bid: IBid
	jobCompletedOn?: Date
}) => {
	// TODO: Move formatting to utils function so support for currencies is focused there.
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: bid.amountCurrency || 'USD'
	})
	console.log(bid)
	return (
		<BidContainer>
			<FallbackAvatar
				src={bid.contractor.avatar && bid.contractor.avatar.url}
				rating={bid.contractor.user.rating}
				name={bid.contractor.companyName}
				size={40}
			/>
			<BidItemTitle>{bid.contractor.companyName}</BidItemTitle>
			<BidPrice>
				{formatter.format(bid.amount)}
				{jobCompletedOn && (
					<Typography style={{ fontSize: 12, opacity: 0.9, color: 'white' }}>
						Completed: {new Date(jobCompletedOn).toLocaleDateString()}
					</Typography>
				)}
			</BidPrice>
		</BidContainer>
	)
}
type Styled<T> = T extends React.ComponentType<infer P>
	? React.ComponentType<Partial<P>>
	: T

const BidItemTitle = styled<Styled<typeof Typography>>(Typography)`
	&& {
		margin-left: 12px;
		font-size: 16px;
		transition: opacity 0.3s ease-out;
		letter-spacing: 0.15px;
		font-weight: 500;
		margin-right: 12px;
		max-width: Calc(100% - 128px);
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		color: white;
	}
`

const BidContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`

const BidPrice = styled<Styled<typeof Typography>>(Typography)`
	&& {
		font-size: 20px;
		letter-spacing: 0.19px;
		text-align: right;
		margin-left: auto;
		font-weight: semibold;
		color: white;
	}
`

export default BidTitle
