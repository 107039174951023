import React from 'react'
import styled from 'styled-components'
import { Contractor } from '../../../apollo/types';
import UpdateBasicInfo from '../../contractor/ContractorProfile/UpdateBasicInfo';
import ConfigurableImage from '../../ui/ConfigurableImage';

interface Props {
  contractor: Contractor
}

const ContractorProfile = ({ contractor }: Props) => {
  return <>
    <UpdateBasicInfo dark readOnly compact providedContractor={contractor} />
    <ImgContain>{
      (contractor.workPictureUrls || []).map(u => (
        <ConfigurableImage src={u} key={u} imageStyle={{ objectFit: 'cover', border: '1px solid gray', borderRadius: 4 }} lightboxOnClick />
      ))
    }</ImgContain>
  </>
}

export default ContractorProfile

const ImgContain = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > * {
    color: white;
    margin: 6px;
  }
`