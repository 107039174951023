import React, { useState } from 'react'
import styled from 'styled-components'
import JobTitleArea from './titleArea'
import BidItem from './bidItem'
import JobBack from './JobBack'
import { Job } from '../../apollo/types'
import { Typography } from '@material-ui/core'
import { TransparentCard } from '../ui/TransparentCard'
import ResponsiveModal from '../ui/ResponsiveModal'

export const CardWrapper = ({ children }) => {
	return <Container><Front>{children}</Front></Container>
}

const JobCard = ({
	job,
	refetch,
	renoworksId,
	legacyHomeSpecId,
	openBid = null,
	extraInfo
}: {
	job: Job;
	refetch?: () => void
	renoworksId: string
	legacyHomeSpecId: string
	openBid?: string,
	extraInfo?: string
}) => {
	const [open, setOpen] = useState(false)
	const toggleOpen = () => {
		!!open && refetch && refetch()
		setOpen(!open)
	}
	return (
		<>
			<CardWrapper>
				{/* TODO: should send entire job */}
				{extraInfo && <Typography variant="body2">{extraInfo}</Typography>}
				<JobTitleArea
					thumbnailSlug={job.thumbnailSlug}
					title={job.title}
					description={job.description}
					createdAt={job.createdAt}
					viewDetailsPressed={toggleOpen}
					date={new Date(job.date)}
					id={job.id}
					refetch={refetch}
				/>
				<Bids>
					{job.bids && job.bids.length > 0 ? (
						job.bids.sort((b1, b2) => {
							const v1 = b1.status === 'REJECTED' ? 1 : -1
							const v2 = b2.status === 'REJECTED' ? 1 : -1
							return v1 - v2
						}).map(bid => <BidItem job={job} bid={bid} jobCompleted={!!job.completedOn} key={bid.id} refetch={refetch} defaultOpen={openBid === bid.id}/>)
					) : (
							<>
								<Typography variant="subtitle1" align="center" gutterBottom><em>Waiting for bids</em></Typography>
								<Typography variant="body2" align="center" style={{fontSize: 13, opacity: .85}}>
									Upon submitting a bid, a chat feature is unlocked between you and the bidding contractor,
									<br/>
									so you can discuss timeframes and other important job details.
								</Typography>
							</>
						)}
				</Bids>
			</CardWrapper>
			<ResponsiveModal dark onClose={toggleOpen} open={open}>
				<JobBack
					onClose={toggleOpen}
					hasHiredBid={!!job.bids && job.bids.length > 0 && !!job.bids.find(j => j.status === 'HIRED')}
					refetch={refetch}
					isOwner
					job={job}
					visible={open}
					renoworksId={renoworksId}
					legacyHomeSpecId={legacyHomeSpecId}
				/>
			</ResponsiveModal>
		</>
	)
}

const Front = styled(TransparentCard)`
	min-height: 320px;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	background-color: #2c3e50 !important;
	* {
		color: white;
    border-color: white
	}

	@media (max-width: 850px) {
		padding: 10px;
	}
`

const Container = styled.div`
	position: relative;
	margin-bottom: 40px;
	overflow: hidden;
`

const Bids = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	justify-content: center;
	max-height: 440px;
	overflow-y: auto;

	@media (max-width: 850px) {
		> * {
			text-align: left;
			padding: 4px;
		}
	}
`

export default JobCard
