import React, { FunctionComponent, useState } from 'react'
import { makeStyles } from '@material-ui/styles';
import TextField from '@material-ui/core/TextField';
import { Button, Typography } from '@material-ui/core';
import MailIcon from '@material-ui/icons/Mail';
import { gql } from "apollo-boost"
import { useMutation } from '@apollo/react-hooks';
import Swal from 'sweetalert2';
import styled from 'styled-components';

const SUGGEST_TO_CONTRACTOR = gql`
    mutation SuggestJob(
    $email: String!
    $id: ID!
    ) {
        suggestJob (
            email: $email
            id: $id
        ) {
            email
            success
        }
    }
`;

interface Props {
  jobId: string
}

const useStyles = makeStyles({
  root: {
    color: "white",
    borderBottom: '2px solid rgb(255, 255, 255) !important'
  }
});

const SuggestToContractor: FunctionComponent<Props> = ({ jobId }: Props) => {
  const [contractorEmail, setContractorEmail] = useState("");
  const [suggestToContractor] = useMutation(SUGGEST_TO_CONTRACTOR);
  const classes = useStyles();

  return (
    <Container>
      <Typography variant="body2" style={{fontSize: 13.5, marginTop: '1rem'}}>Know a Contractor? Invite Anyone to Bid!</Typography>
      <form
        onSubmit={e => {
          e.preventDefault()
          suggestToContractor({
            variables: {
              id: jobId,
              email: contractorEmail,
            },
            update: (proxy, mutationResult) => {
              Swal.fire({
                title: 'Success',
                text: 'The Contractor Will Receive an Invitation Email Shortly',
                type: 'success'
              })
              setContractorEmail("")
            },
          });
        }}
      >
        <TextField
          onChange={e => setContractorEmail(e.target.value)}
          placeholder="Contractor Email"
          value={contractorEmail}
          color="white"
          inputProps={{
            className: classes.root
          }}
          style={{ fontSize: 14, minWidth: 200 }}
        />
        <Button type="submit"><MailIcon /></Button>
      </form>
    </Container>
  )
}

export default SuggestToContractor

const Container = styled.div`
  *:before {
    border-color: white;
  }
`
