import React from 'react'
import { Typography } from '@material-ui/core'
import styled from 'styled-components'
import IconCard from '../ui/IconCard'
import ErrorIcon from '@material-ui/icons/Error';

const PaymentFailed = () => (
  <Outer>
    <IconCard icon={<StyledErrorIcon />}>
      <div>
        <Typography variant="h6">We're Sorry! There was a problem with your payment.</Typography>
        <Typography variant="subtitle1">Please try re-entering your card or contact support.</Typography>
      </div>
    </IconCard>
  </Outer>
)

export default PaymentFailed

const StyledErrorIcon = styled(ErrorIcon)`
  font-size: 55px;
  color: darkred;
`;

const Outer = styled.div`
	display: flex;
	flex: 1;
	align-items: center;
  justify-content: center;
  height: 100%;
`
