import * as React from 'react'

import JobSidebar from '../../components/jobSidebar'
import DisastrousErrorHandler from '../../components/ui/DisastrousErrorHandler'
import SEO from '../../components/seo/seo'
import ActiveJobs from '../../components/activeJobs/activeJobs'
import { Bid, SharedJob, ContractorJob, PropertyStatus, PropertyMeasurements } from '../../apollo/types'
export { BidStatus } from '../../apollo/types'

export interface IBid extends Bid {
	avatar_uri?: string
}

export interface IContractorJob extends ContractorJob {
	myBid: IBid,
	property: IBiddableProperty | IBiddablePropertyDetails,
	hasSelectedBid: boolean // User has selected one of the bids.
	contractorChargeAmount: number
	iconName: string
	propertyMeasurements: PropertyMeasurements | null
}

export interface IBiddableProperty {
	id: string,
	zipCode: string
	city: string
	state: string
	owner: {
		firstName: string
	}
	ready: PropertyStatus
}

export interface IBiddablePropertyDetails extends IBiddableProperty {
	streetName: string
	houseNumber: string
	owner: {
		firstName: string
		lastName: string
	}
}

export interface ISelection {
	id: string,
	question: string,
	answer: string,
}

export interface IPicture {
	id: string,
	url: string,
}

export interface IJob extends SharedJob {
	home_id: string
	createdAt: Date
	bids: IBid[]
	icon_name: string
}

// IJob can be removed. Only referenced in dummyData. Leaving for compatibility.
//export interface IJob extends Job { } // eslint-ignore-line

export interface IHome {
	id: string
	street_address: string
	avatar_uri: string
	not_yet_ready?: boolean
}

const IndexPage = () => (
	<DisastrousErrorHandler>
		<JobSidebar>
			<SEO title="Active jobs" keywords={['3d', 'construction']} />
			<ActiveJobs />
		</JobSidebar>
	</DisastrousErrorHandler>
)

export default IndexPage
