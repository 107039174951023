import * as React from 'react'
import styled from 'styled-components'
import { Typography } from '@material-ui/core'
import ChevronRight from '@material-ui/icons/ChevronRight'
import { IBid, BidStatus } from '../../pages/jobs'
import FallbackAvatar from '../ui/FallbackAvatar'
import { Chat } from '@material-ui/icons';
import ResponsiveModal from '../ui/ResponsiveModal';
import JobBidModal from '../modals/jobBidModal';
import { Job } from '../../apollo/types';

const BidItem = ({ job, bid, refetch, jobCompleted, defaultOpen = false, }: { job: Job, bid: IBid; refetch?: () => void, jobCompleted?: boolean, defaultOpen?: boolean }) => {
	const [open, setOpen] = React.useState(defaultOpen)
	const toggleOpen = () => {
		!!open && refetch && refetch()
		setOpen(!open)
	}

	// TODO: Move formatting to utils function so support for currencies is focused there.
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: bid.amountCurrency || 'USD',
	});

	let statusText = bid.status || "No status";
	if (bid.status === BidStatus.SUBMITTED) {
		statusText = "Bid Submitted";
	} else if (bid.status === BidStatus.HIRED) {
		statusText = jobCompleted ? 'Completed Job' : "Hired For Job";
	} else if (bid.status === BidStatus.REJECTED) {
		statusText = "Bid Rejected";
	}

	return (
		<>
			<BidContainer onClick={()=> setOpen(true)}>
				<FallbackAvatar
				rating={bid.contractor.user && bid.contractor.user.rating}
					src={bid.contractor.avatar && bid.contractor.avatar.url}
					name={bid.contractor.companyName}
					size={40}
				/>
				<BidText>
					{
						(!bid.contractor.companyAdminName && !bid.contractor.companyPosition) ?
							null :
							<BidCompInfo>{
								[bid.contractor.companyAdminName,bid.contractor.companyPosition]
									.filter(r => !!r)
									.join(', ')
							}</BidCompInfo>
					}
					<BidItemTitle>{bid.contractor.companyName}</BidItemTitle>
					<BidItemDesc>{statusText}</BidItemDesc>
				</BidText>
				{bid.hasUnreadMessages && <Chat color="primary" />}
				<BidPrice>{`${formatter.format(bid.amount)}`}</BidPrice>
				<BidArrow />
			</BidContainer>
			<ResponsiveModal dark style={{ height: 800 }} open={open} onClose={toggleOpen}>
				<JobBidModal
					onClose={toggleOpen}
					job={job}
					bid={bid}
				/>
			</ResponsiveModal>
		</>
	)
}

const BidText = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: center;
	margin-left: 16px;

	@media (min-width: 1022px) {
		margin-right: 12px;
		max-width: Calc(100% - 128px);
		position: relative;

		> * {
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
		}
	}
`

type Styled<T> = T extends React.ComponentType<infer P> ? React.ComponentType<Partial<P>> : T;

const BidItemTitle = styled<Styled<typeof Typography>>(Typography)`
	&& {
		font-size: 16px;
		opacity: 0.87;
		color: #fff;
		transition: opacity 0.3s ease-out;
		letter-spacing: 0.15px;
		font-weight: 500;
	}
`

const BidItemDesc = styled<Styled<typeof Typography>>(BidItemTitle)`
	&& {
		font-size: 12px;
		font-weight: 300;
		opacity: 0.65;
		transition: opacity 0.3s ease-out;
	}
`

const BidCompInfo = styled<Styled<typeof Typography>>(BidItemDesc)`
	&& {
		opacity: 1;
    font-weight: bold;
    font-size: 11px;
	}
`

const BidContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	min-height: 64px;
	padding-top: 9px;
	padding-bottom: 9px;
	padding-right: 10px;
	padding-left: 10px;
	width: 100%;
	margin-left:-2px;
	margin-right:2px;
	cursor: pointer;
	&:not(:first-child) {
		border-top: 0.5px solid rgba(255, 255, 255, 0.3);
	}

	&:hover {
		background-color: rgba(0, 0, 0, 0.05);
	}

	@media (min-width: 850px) {
		&:hover {
			${BidItemTitle}, ${BidItemDesc} {
				opacity: 1;
			}
		}
	}
`

const BidPrice = styled<Styled<typeof Typography>>(Typography)`
	&& {
		font-size: 20px;
		color: #fff;
		letter-spacing: 0.19px;
		text-align: right;
		margin-left: 16px;
		font-weight: semibold;
	}
`

const BidArrow = styled(ChevronRight)`
	&& {
		color: rgba(50,50,255,.7);
		opacity: 0.65;
		font-size: 24px;
		right: 0;
		margin-right: -12px;
		margin-left: 12px;
		margin-top: -2px;
	}
` as typeof ChevronRight

export default BidItem
