import * as React from 'react'
import styled from 'styled-components'
import JobCardWithBids from '../jobCardWithBids'
import HouseNotReady from './HouseNotReady'
import NoJobsYet from './NoJobsYet'
import HouseDenied from './HouseDenied'
import NoPropertiesYet from './NoPropertiesYet'
import PaymentFailed from './PaymentFailed'
import { useQuery } from '@apollo/react-hooks'
import { GET_SELECTED_PROPERTY_ID } from '../../apollo/queries'
import { Tabs, Tab, Typography } from '@material-ui/core'
import { Archive, Build } from '@material-ui/icons'
import Loading from '../ui/Loading'
import { Job } from '../../apollo/types'
import Snackbar from '@material-ui/core/Snackbar'
import queryString from 'query-string'
import { Link } from 'gatsby'
import { useHomeownerJobsQuery } from '../../apollo/generated'
import Pagination from '../ui/Pagination'

const JobsContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: stretch;
	padding: 12px 40px 40px 40px;
	width: auto;
	height: auto;
	min-height: 100%;
	background-color: #152747;
	@media (max-width: 850px) {
		padding: 16px;
		padding-top: 16px;
		padding-bottom: 66px;
	}
`

const NoJobsInFilter = styled(Typography)`
	color: white;
	text-align: center;
	font-size: 18px;
	font-style: italic;
	margin-top: 25vh;
`

const ErrorSnackbar = styled(Snackbar)`
	& > div {
		background-color: darkred;
	}
`

const SingleJobWarning = styled(Typography)`
	color: white;
	font-size: 21px;
	margin-bottom: 20px;
`

const StyledLink = styled(Link)`
	color: inherit;
`

export enum PropertyStatus {
	IN_PROGRESS = 'IN_PROGRESS',
	READY = 'READY',
	DENIED = 'DENIED',
	PAYMENT_FAILED = 'PAYMENT_FAILED',
	CANCELED = 'CANCELED'
}

const ActiveJobs = () => {
	const [tab, setTab] = React.useState(0)
	const [page, setPage] = React.useState(0)
	const [urlJobId, setUrlJobId] = React.useState<null | string>(null)
	const [jobNotFound, setJobNotFound] = React.useState(false)
	const handleTabChange = (e: React.ChangeEvent<{}>, newVal: number) => {
		setTab(newVal)
		setPage(0)
	}

	React.useEffect(() => {
		if (typeof window !== 'undefined') {
			const queryStrings = queryString.parse(window.location.search)
			const wantedJobId = (queryStrings.job_id as string) || null
			wantedJobId && setUrlJobId(wantedJobId)
		}
	}, [])

	const {
		data: { selectedPropertyId }
	} = useQuery(GET_SELECTED_PROPERTY_ID)
	const { data, loading, refetch } = useHomeownerJobsQuery({
		fetchPolicy: 'network-only',
		variables: {
			jobId: urlJobId,
			propertyIds: selectedPropertyId ? [selectedPropertyId] : null,
			view: tab === 0 ? 'ONGOING' : 'ARCHIVE',
			page
		}
	})

	if (loading || !data || !data.homeowner || !data.homeownerJobs)
		return <Loading />
	const { homeownerJobs, homeowner } = data

	const properties = (homeowner && homeowner.properties) || []
	if (properties.length === 0) {
		return <NoPropertiesYet />
	}

	const selected_property = properties.find(p => p.id === selectedPropertyId)
	let wantedProperties: { id?: string; ready?: any }[]
	if (selected_property) {
		wantedProperties = [selected_property]
	} else {
		wantedProperties = properties
	}

	if (typeof window !== 'undefined' && urlJobId) {
		const queryStrings = queryString.parse(location.search)
		const bidId = queryStrings.bid_id
		const job = homeownerJobs.find(j => j.id === urlJobId)
		if (!job) {
			return (
				<SingleJobWarning>
					Sorry! We could not find this job.
					<StyledLink to={'/jobs'}>
						click here to go back to all jobs.
					</StyledLink>
				</SingleJobWarning>
			)
		}
		if (bidId && !job.bids.find(b => b.id === bidId)) {
			return (
				<SingleJobWarning>
					Sorry! We could not find that particular bid.
					<StyledLink to={'/jobs'}>
						click here to go back to all jobs.
					</StyledLink>
				</SingleJobWarning>
			)
		}
		return (
			<>
				<JobsContainer>
					<SingleJobWarning>
						You'be been linked to this particular job,{' '}
						<StyledLink to={'/jobs'}>click here to see all.</StyledLink>
					</SingleJobWarning>
					<JobCardWithBids
						refetch={refetch}
						renoworksId={job.property.renoworksId}
						legacyHomeSpecId={`${job.property.legacyHomeSpecId}`}
						key={job.id!}
						job={(job as unknown) as Job}
						openBid={bidId as string}
					/>
				</JobsContainer>
			</>
		)
	}

	const hasNoJobs = (data.homeownerOngoingJobsCount + data.homeownerArchivedJobsCount) === 0;
	if (wantedProperties.every(p => p.ready === PropertyStatus.CANCELED || p.ready === PropertyStatus.PAYMENT_FAILED)) {
		if (hasNoJobs) {
			return <PaymentFailed />
		}
	} else if (wantedProperties.every(p => p.ready === PropertyStatus.Denied)) {
		return <HouseDenied />
	} else if (hasNoJobs) {
		return <NoJobsYet single={wantedProperties.length === 1} />
	}

	return (
		<JobsContainer>
			<Tabs
				style={{ marginBottom: 24 }}
				value={tab}
				onChange={handleTabChange}
				centered
			>
				<Tab
					icon={<Build />}
					style={{ color: 'white' }}
					label={`Ongoing Jobs (${data.homeownerOngoingJobsCount || 0})`}
				/>
				<Tab
					icon={<Archive />}
					style={{ color: 'white' }}
					label={`Job Archive (${data.homeownerArchivedJobsCount || 0})`}
				/>
			</Tabs>
			{homeownerJobs.length === 0 && (
				<NoJobsInFilter key="nofilter">
					No {tab === 0 ? 'Ongoing' : 'Archived'} Jobs to Display
				</NoJobsInFilter>
			)}
			{homeownerJobs &&
				homeownerJobs.map(job => (
					<JobCardWithBids
						refetch={refetch}
						renoworksId={job.property.renoworksId}
						legacyHomeSpecId={`${job.property.legacyHomeSpecId}`}
						key={job.id!}
						job={(job as unknown) as Job}
					/>
				))}
			<ErrorSnackbar
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left'
				}}
				open={jobNotFound}
				autoHideDuration={5000}
				onClose={() => setJobNotFound(false)}
				ContentProps={{
					'aria-describedby': 'message-id'
				}}
				message={
					<span id="message-id">Could not find the job on your open jobs.</span>
				}
			/>
			{homeownerJobs && homeownerJobs.length > 0 && (
				<Pagination
					count={
						(tab === 0
							? data.homeownerOngoingJobsCount
							: data.homeownerArchivedJobsCount) || 0
					}
					page={page}
					setPage={p => setPage(p)}
				/>
			)}
		</JobsContainer>
	)
}

export default ActiveJobs
